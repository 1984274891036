
import { defineComponent, reactive, computed, onMounted, ref } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'

export default defineComponent({
  name: 'EXAMPLE',
  setup () {
    const formSize = ref('default')
    const ruleFormRef = ref<FormInstance>()
    const ruleForm = reactive({
      name: 'Hello',
      region: '',
      count: '',
      date1: '',
      date2: '',
      delivery: false,
      rate: 4.9,
      percentage: 0,
      slider: [10, 50],
      type: [],
      resource: '',
      desc: ''
    })

    const rules = reactive<FormRules>({
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
        { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
      ],
      region: [
        {
          required: true,
          message: 'Please select Activity zone',
          trigger: 'change'
        }
      ],
      count: [
        {
          required: true,
          message: 'Please select Activity count',
          trigger: 'change'
        }
      ],
      date1: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a date',
          trigger: 'change'
        }
      ],
      date2: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a time',
          trigger: 'change'
        }
      ],
      type: [
        {
          type: 'array',
          required: true,
          message: 'Please select at least one activity type',
          trigger: 'change'
        }
      ],
      resource: [
        {
          required: true,
          message: 'Please select activity resource',
          trigger: 'change'
        }
      ],
      desc: [
        { required: true, message: 'Please input activity form', trigger: 'blur' }
      ]
    })

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          console.log('submit!')
        } else {
          console.log('error submit!', fields)
        }
      })
    }

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.resetFields()
    }

    const options = Array.from({ length: 10000 }).map((_, idx) => ({
      value: `${idx + 1}`,
      label: `${idx + 1}`
    }))

    const marks = { 0: '--', 20: '20%', 40: '40%', 60: '60%', 80: '80%', 100: '100%' }

    const colors = [
      { color: '#f56c6c', percentage: 20 },
      { color: '#e6a23c', percentage: 40 },
      { color: '#5cb87a', percentage: 60 },
      { color: '#1989fa', percentage: 80 },
      { color: '#6f7ad3', percentage: 100 }
    ]

    const size = ref('')
    const iconStyle = computed(() => {
      const marginMap = {
        small: '4px',
        large: '8px',
        default: '6px'
      }
      return {
        marginRight: marginMap.small || marginMap.default
      }
    })

    onMounted(() => {
      setInterval(() => {
        ruleForm.percentage = (ruleForm.percentage % 100) + 10
      }, 500)
    })

    return {
      formSize,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      resetForm,
      options,
      marks,
      colors,
      size,
      iconStyle
    }
  }
})

